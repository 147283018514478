body {
  margin: 0;
  background-color: #6E6D6D;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Helvetica', sans-serif, 'Segoe UI';
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Helvetica', sans-serif, 'Segoe UI',
    monospace;
}
